import { decryptedLocalStorage, encryptedLocalStorage } from "./crypto";

export const setLocalStorageRecord = (key, value) => {
  const localStorageItems = localStorage.getItem("data");
  let decryptingRecord = {};
  if (localStorageItems) {
    const decrypting = decryptedLocalStorage("123456789", localStorageItems);
    decryptingRecord = JSON.parse(decrypting);
  }
  const data = JSON.stringify({ ...decryptingRecord, [key]: value });
  const encrypting = encryptedLocalStorage("123456789", data);
  localStorage.setItem("data", encrypting);
};
const decryptLocalStorageData = () => {
  const value = localStorage.getItem("data");
  if (value) {
    const decrypted = decryptedLocalStorage("123456789", value);
    return JSON.parse(decrypted);
  }
  return null;
};
export const getLocalStorageValue = (key) => {
  const data = decryptLocalStorageData();
  return data?.[key] ?? false;
};
