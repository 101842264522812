import React from "react";
import { Navigate } from "react-router-dom";
import DrawerIndex from "../pages/DrawerIndex";
import { getLocalStorageValue } from "../http/services/local-storage-service";
// import { Link } from "react-router-dom";

const PrivateRoute = ({ isLayout, children }) => {
  return getLocalStorageValue("authUser") &&
  getLocalStorageValue("authAwsCred") &&
    getLocalStorageValue("authCognitoId") ? (
    <>{isLayout ? <DrawerIndex>{children}</DrawerIndex> : children}</>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
