import { useState } from "react";
import {
  getLocalStorageValue,
  setLocalStorageRecord,
} from "./local-storage-service";

export const useLocalStorage = () => {
  const [value, setValue] = useState(null);
  const setItem = (key, value) => {
    setLocalStorageRecord(key, value);
    setValue(value);
  };

  const getItem = (key) => {
    const value = getLocalStorageValue(key);
    setValue(value);
    return value;
  };

  const removeItem = (key) => {
    setLocalStorageRecord(key, null);
    setValue(null);
  };

  return { setItem, getItem, removeItem };
};
