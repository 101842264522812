import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/components/index.css";
import "./pages/index.css";
import "../src/index.css";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import AppRoutes from "./routes";
import LoaderContext from "./context/LoaderContext";
import AwsAmplifyInit from "./http/services/aws-amplify";
import { AuthContext, useAuth } from "./context/AuthContext";
import { SnackbarProvider } from "notistack";
import { AxiosInterceptorContextProvider } from "./context/AxiosInterceptor";
import { getLocalStorageValue } from "./http/services/local-storage-service";

const App = () => {
  AwsAmplifyInit();
  const [isLoader, setIsLoader] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const { startAutoLogoutTimer, sessionAutoLogoutTimerClear } = useAuth();
  useEffect(() => {
    if (localStorage?.getItem("data")) {
      setAuthUser(
        getLocalStorageValue("authUser")
          ? JSON.parse(getLocalStorageValue("authUser"))
          : null
      );
    } else {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    startAutoLogoutTimer();
    return () => sessionAutoLogoutTimerClear();
  }, []);

  return (
    <BrowserRouter basename="/">
      <AxiosInterceptorContextProvider>
        <AuthContext.Provider value={{ authUser, setAuthUser }}>
          <LoaderContext.Provider value={{ isLoader, setIsLoader }}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Provider store={store}>
                <ThemeProvider theme={theme}>
                  <AppRoutes />
                </ThemeProvider>
              </Provider>
            </SnackbarProvider>
          </LoaderContext.Provider>
        </AuthContext.Provider>
      </AxiosInterceptorContextProvider>
    </BrowserRouter>
  );
};

export default App;
